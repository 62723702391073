const ClaimKeys = {
  CanChangeEmployeeMobilization: "CanChangeEmployeeMobilization",
  CanChangeEmployeement: "CanChangeEmployeement",
  CanListQuiz: "CanListQuiz",
  CanModifyQuiz: "CanModifyQuiz",
  CanListPlans: "CanListPlans",
  CanListElectronicContent: "CanListElectronicContent",
  CanEnterKpi: "CanEnterKpi",
  CanChangeCampus: "CanChangeCampus",
  CanViewStudentCounter: "CanViewStudentCounter",
  CanViewMyCampusManagement: "CanViewMyCampusManagement",
  CanListNotifications: "CanListNotifications",
  CanModifyNotifications: "CanModifyNotifications",
  CanListUsers: "CanListUsers",
  CanModifyUsers: "CanModifyUsers",
  CanViewFeeCounter: "CanViewFeeCounter",
  CanViewTempEmpList: "CanViewTempEmpList",
  CanViewStudentAreaWise: "CanViewStudentAreaWise",
  CanViewEmployeeCounter: "CanViewEmployeeCounter",
  CanViewCampusPerformance: "CanViewCampusPerformance",
  CanViewKPIDailyReportDashboard: "CanViewKPIDailyReportDashboard",
  CanViewKPIDailyReport: "CanViewKPIDailyReport",
  CanViewKPIDailyReportList: "CanViewKPIDailyReportList",
  CanAccessExitProforma: "CanAccessExitProforma",
  CanMarkExitConfirmStudent: "CanMarkExitConfirmStudent",
  CanMarkRefundSecurityDeposit: "CanMarkRefundSecurityDeposit",
  CanViewExitStudentsTracking: "CanViewExitStudentsTracking",
  CanViewCampusUpKeep: "CanViewCampusUpKeep",
  CanSubmitCampusUpKeep: "CanSubmitCampusUpKeep",
  CanVeiwSubjectsInSection: "CanVeiwSubjectsInSection",
  CanViewBigXSetup: "CanViewBigXSetup",
  CanViewEmployeeAttendance: "CanViewEmployeeAttendance",
  CanAccessRoomManagement: "CanAccessRoomManagement",
  CanViewFeeApproval: "CanViewFeeApproval",
  CanViewVanManagement: "CanViewVanManagement",
  CanViewAdminResource:"CanViewAdminResource",
  CanAccessFeePosting:"CanAccessFeePosting",
  CanAccessBankAccountList:"CanAccessBankAccountList",
  CanAddEditBankAccount:"CanAddEditBankAccount",
  CanAcccessGlReports:"CanAcccessGlReports",
  CanAccessBankReconcilation:"CanAccessBankReconcilation",
  CanAccessChequeStatus:"CanAccessChequeStatus",
  CanViewFeeChallan:"CanViewFeeChallan",
  CanModifyFeeChallan:"CanModifyFeeChallan",
};

export default ClaimKeys;
